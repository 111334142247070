import {makeStyles} from "tss-react/mui";

/** @type {Object} */
export default makeStyles()((theme) => ({
    root: {
        width: "100%",
        background: theme.palette.background.paper
    },
    title: {
        height: "78px",
        textTransform: "uppercase",
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        color: theme.palette.common.black,
        fontWeight: theme.typography.fontWeightBold,
        padding: "30px 40px"
    },
    content: {
        "height": "calc(100% - 174px)", // 78 + 96
        "display": "flex",
        "flexDirection": "column",
        "overflow": "auto",
        "& .MuiTableCell-root": {
            lineHeight: "50px"
        }
    },
    text: {
        padding: "12px 30px",
        fontSize: "0.875rem"
    },
    resetButton: {
        margin: "12px 30px",
        alignSelf: "flex-start",
        fontSize: "0.875rem",
        textTransform: "Unset"
    },
    footer: {
        height: "96px",
        borderTop: `1px solid ${theme.palette.grey[200]}`,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 40px"
    },
    dayWrapper: {
        "position": "relative",
        "& button": {
            fontSize: "15px",
            background: theme.palette.background.paper
        }
    },
    dayWrapperSelected: {
        "& button": {
            background: `${theme.palette.primary.main} !important`,
            color: `${theme.palette.primary.contrastText} !important`
        }
    },
    dayWrapperToday: {
        "& button": {
            color: `${theme.palette.primary.main} !important`
        }
    },
    todayDot: {
        width: "4px",
        height: "4px",
        borderRadius: "2px",
        backgroundColor: theme.palette.primary.main,
        position: "absolute",
        bottom: "5px",
        left: "18px"
    },
    todayDotSelected: {
        backgroundColor: theme.palette.primary.contrastText
    }
}));
