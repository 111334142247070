// @ts-check
import {useTheme} from "@mui/material";
import {PickersDay, pickersDayClasses} from "@mui/x-date-pickers";
import {bool, object} from "prop-types";
import React from "react";

import useStyles from "../print_layer.styles";

/** @typedef {import("@mui/x-date-pickers").PickersDayProps} PickersDayProps */

/**
 * CustomPickerDayPrint component
 * @param {Object} props
 * @param {DateTimeType} props.date
 * @param {PickersDayProps} props.pickersDayProps
 * @param {Boolean} props.isSelected
 * @param {Boolean} props.isPrintAllowed
 * @return {React.ReactElement}
 */
const CustomPickerDayPrint = ({date, pickersDayProps, isSelected, isPrintAllowed}) => {
    const {classes, cx} = useStyles();
    const {primary} = useTheme().palette;

    if (!date) {
        return <PickersDay {...pickersDayProps} />;
    }

    return (
        <div
            className={cx(classes.dayWrapper, {
                [classes.dayWrapperSelected]: isSelected,
                [classes.dayWrapperToday]: pickersDayProps.today && !isSelected
            })}
        >
            {
                <PickersDay
                    {...pickersDayProps}
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    disabled={!isPrintAllowed}
                    sx={{
                        [`&&.${pickersDayClasses.selected}`]: {
                            backgroundColor: primary.main
                        }
                    }}
                />
            }
            {pickersDayProps.today && !pickersDayProps.outsideCurrentMonth && (
                <span
                    className={cx(classes.todayDot, {
                        [classes.todayDotSelected]: isSelected
                    })}
                />
            )}
        </div>
    );
};

CustomPickerDayPrint.propTypes = {
    date: object.isRequired,
    pickersDayProps: object.isRequired, // PickersDayProps
    isSelected: bool,
    isPrintAllowed: bool
};

export default CustomPickerDayPrint;
