// @ts-check

import {changeLanguage, t} from "i18next";

import config from "../../../config/config.json";
import {DATE_FORMATS, luxonToken} from "../../contexts/dates";
import {formatOpTeam} from "../../utils/format_op_team";
import {getParticipantCategories} from "../../utils/get_participant_category";
import {diffDateTime, formatWithLocale, getDateTimeFromISO, minusDT, nowDateTimeWithTimezone, startOf} from "../../utils/luxon_helpers";
import {formatObservation} from "../../utils/patient_helpers";
import buildPatientInfo from "../private_data/utils/build_patient_info";
import getPractitionerIdsFromScheduleOpByCategory from "../private_data/utils/get_practitioner_ids_from_schedule_ops_by_category";

/**
 * format the information from the patient observation and note excluding the weight and height

 * @param {ObservationsPatient} observations
 * @param {Array<{text: String}>} note
 * @return {Array<String>}
 */
const formatObservations = (observations, note) => {
    const notes = [];
    const {allergyAndImmunology, infectiousDisease, infectiousStatus} = observations;
    if (allergyAndImmunology?.value) {
        notes.push(`${t("PrintLayer.allergy")}: ${allergyAndImmunology.value}`);
    }
    if (infectiousDisease?.value || infectiousStatus?.value) {
        const infectionList = [infectiousDisease?.value, infectiousStatus?.value].filter(Boolean);
        notes.push(`${t("PrintLayer.infection")}: ${infectionList.join(", ")}`);
    }
    if (note?.length) {
        notes.push(note.map((note) => note.text).join(" "));
    }
    return notes;
};

const tableHeaderKeys = ["room", "opStart", "patientAndSurgery", "insurance", "details", "surgeons"];

/**
 * here is the order of on call duties in the print footer defined
 */
const orderOfPrintDuties = {
    onCallSpecial1: 1,
    onCall1: 2,
    standBy1: 3,
    pediatricOnCall1: 4,
    anesOnCallEarly1: 5,

    anesOnCallDay1: 6,
    anesOnCallNight1: 7,
    anesStandBy1: 8,
    wardNight1: 9,
    wardNight3: 10,

    wardDay1: 11,
    wardDay2: 12,
    wardDay3: 13,
    wardDay4: 14
};

/**
 * Generate print data
 * @comment this function is async because it is called within the async function and in this case i18next won't work
 *
 * @param {Object} params
 * @param {Array<PlanBox>} params.surgeries
 * @param {Array<{role: String, hcServiceId: String, practitionerIds: Array<String>}>} params.onCall
 * @param {DateTimeType} params.date
 * @param {String} params.timezone
 * @param {String} params.locale "en-US" or "de"
 * @param {Object} params.practitionerNamesObject
 * @param {Object} params.patientPrivateInfos
 * @param {Array<{id: String, name: String}>} params.roomInfos
 * @param {ParticipantCategoriesForHealthcareService[]} params.participantCategoriesForHealthcareService
 * @param {string} params.dayNote
 * @return {Promise<PrintData>}
 */
const generatePrintData = async ({
    surgeries,
    onCall,
    date,
    timezone,
    locale,
    practitionerNamesObject,
    patientPrivateInfos,
    roomInfos,
    participantCategoriesForHealthcareService,
    dayNote
}) => {
    const {
        TEXT_PUNCTUATION: {VERTICAL_SLASH, HYPHEN}
    } = config;

    // The print version is always output in German #15178
    await changeLanguage("de");

    const tableHeader = {
        room: "",
        opStart: "",
        patientAndSurgery: "",
        insurance: "",
        details: "",
        surgeons: ""
    };
    tableHeaderKeys.forEach((key) => {
        tableHeader[key] = t(`PrintLayer.tableHeader.${key}`);
    });

    const startOfToday = startOf(nowDateTimeWithTimezone(timezone), "day");

    return {
        header: {
            createdAt: {
                label: t("PrintLayer.printedAt"),
                display:
                    formatWithLocale(nowDateTimeWithTimezone(timezone), DATE_FORMATS.DATE_TIME_FORMAT, locale) +
                    " " +
                    t("PrintLayer.printTime")
            },
            scheduledOn: {
                label: t("PrintLayer.opPlanFor"),
                display: formatWithLocale(date, DATE_FORMATS.DATE_FULL_WEEKDAY, locale)
            }
        },
        body: {
            note: {
                label: t("PrintLayer.note"),
                display: dayNote || HYPHEN
            },
            tableHeader,
            tableData: surgeries.map((surgery, index) => {
                // destruct used properties
                const {
                    id,
                    _status: status,
                    _interventions: interventions,
                    _location: {reference: locationId},
                    _insuranceType: insuranceType,
                    _patient: {id: patientId, observations, locations},
                    _medicalApproval: medicalApproval,
                    _internalTimestamps: {duraRoomLockPre},
                    _serviceRequestNote: note,
                    _healthcareService: healthcareService,
                    _team: team,
                    _surgeonPresenting: surgeonPresenting,
                    _comment: comment,
                    _encounterId: encounterId
                } = surgery;

                const hcServiceId = healthcareService.reference;
                // surgeon names
                const {surgeons, anesthesias} = getPractitionerIdsFromScheduleOpByCategory(
                    team,
                    getParticipantCategories({participantCategoriesForHealthcareService, hcServiceId})
                );
                // Surgeons
                const anesthesiologistIds = anesthesias ?? []; // If `anesthesias` is `null` or `undefined`, use an empty array instead
                const allSurgeonIds = [...surgeons, ...anesthesiologistIds, surgeonPresenting?.reference].filter(Boolean);
                const teamNameList = allSurgeonIds.map((id) => ({id, name: practitionerNamesObject[id] || t("App.unknown")}));
                const surgeonNames = allSurgeonIds.length ? formatOpTeam({surgeons, anesthesias}, teamNameList, surgeonPresenting) : HYPHEN;

                // birthDate, gender, height and weight of patient
                const {
                    name: patientName,
                    birthDate,
                    gender
                } = buildPatientInfo(patientPrivateInfos[patientId], patientId, luxonToken.BIRTH_DATE_FORMAT.de); // The date will be always set in German
                const patientDetails = [
                    birthDate,
                    gender,
                    formatObservation(observations?.weight),
                    formatObservation(observations?.height)
                ];

                // notes - day level
                const notes = formatObservations(observations, note);

                // Station
                const {before, after} = locations || {};

                // Check if the bottom border is bold
                const isLast = surgeries.length - 1 === index;
                const nextLocationId = !isLast && surgeries[index + 1]._location.reference;
                const hasBoldUnderline = nextLocationId !== locationId || isLast;

                return {
                    id,
                    status,
                    interventionDisplay: interventions.main?.[0].display,
                    encounterIdDisplay: `${t("PrintLayer.encounterId")}: ${encounterId || HYPHEN}`, // @todo show the id from the backedn #15158
                    stationDisplay: t("PrintLayer.stationFromTo", {from: before?.ward || HYPHEN, to: after?.ward || HYPHEN}),
                    locationDisplay: roomInfos.find((room) => room.id === locationId)?.name,
                    surgeonNames,
                    patientName,
                    patientDetailsDisplay: patientDetails.join(VERTICAL_SLASH),
                    patientObservationsDisplay: notes.join(VERTICAL_SLASH),
                    patientInsuranceType: insuranceType ? t(`PrintLayer.insuranceType.${insuranceType}`) : HYPHEN,
                    dtStartDisplay: formatWithLocale(getDateTimeFromISO(duraRoomLockPre.dtStart), DATE_FORMATS.TIME, locale),
                    next_medClearance: medicalApproval.find((approval) => approval.type === "next_medClearance")?.practitionerId || "",
                    comment: comment || "",
                    hasBoldUnderline
                };
            })
        },
        footer: {
            data:
                date >= startOfToday
                    ? Object.keys(orderOfPrintDuties)
                          .sort((a, b) => orderOfPrintDuties[a] - orderOfPrintDuties[b])
                          .map((dutyKey) => {
                              const ids = onCall
                                  .filter((duty) => duty.role === dutyKey)
                                  .map((duty) => duty.practitionerIds)
                                  .flat();
                              const pracNames = [...new Set(ids)].map((id) => practitionerNamesObject[id]);
                              return {label: t(`PrintLayer.${dutyKey}`), display: pracNames.join(VERTICAL_SLASH) || HYPHEN};
                          })
                    : [],
            page: t("PrintLayer.page")
        }
    };
};

/**
 * check reporter environment
 * @throws Error
 */
const checkReporter = () => {
    if (!window) {
        throw new Error("Could not generate report, client environment missing");
    }
    if (!window.Stimulsoft) {
        throw new Error("Could not generate report, Stimulsoft library is missing");
    }
};

/**
 * set up the reporter
 *
 * @param {Object} params
 * @param {String} params.licenseKey reporter software license key
 * @param {String} params.reportPath public asset path
 */
const setupReporter = ({licenseKey, reportPath}) => {
    checkReporter();

    // Setup for print
    window.Stimulsoft.Base.StiLicense.key = licenseKey;

    // Adding the font to the resource
    Stimulsoft.Base.StiFontCollection.addOpentypeFontFile(
        `${reportPath}/Roboto-Italic.ttf`,
        "Roboto-Italic",
        Stimulsoft.System.Drawing.FontStyle.Italic
    );
    Stimulsoft.Base.StiFontCollection.addOpentypeFontFile(`${reportPath}/Roboto-Medium.ttf`, "Roboto-Medium");
    Stimulsoft.Base.StiFontCollection.addOpentypeFontFile(`${reportPath}/Roboto-Regular.ttf`, "Roboto-Regular");
};

/**
 * generate and download report
 * @param {Object} params
 * @param {DateTimeType} params.date
 * @param {String} params.systemDate
 * @param {Array<PlanBox>} params.surgeries
 * @param {Array<{role: String, hcServiceId: String, practitionerIds: Array<String>}>} params.onCall
 * @param {Object} params.practitionerNamesObject
 * @param {Object} params.patientPrivateInfos
 * @param {Array<{id: String, name: String}>} params.roomInfos
 * @param {ParticipantCategoriesForHealthcareService[]|undefined} params.participantCategoriesForHealthcareService
 * @param {String} params.reportPath public asset path
 * @param {String} params.timezone
 * @param {String} params.locale "en-US" or "de"
 * @param {String} params.dayNote
 * @return {Promise<void>}
 */
const generateReport = async ({
    date,
    systemDate,
    surgeries,
    onCall,
    practitionerNamesObject,
    patientPrivateInfos,
    roomInfos,
    participantCategoriesForHealthcareService,
    reportPath,
    locale,
    timezone,
    dayNote
}) => {
    checkReporter();

    // Creating a new report instance
    // eslint-disable-next-line
    const report = new window.Stimulsoft.Report.StiReport();

    // Load report from url
    report.loadFile(`${reportPath}/schedule.mrt`);

    const printData = await generatePrintData({
        surgeries,
        onCall,
        date,
        timezone,
        locale,
        practitionerNamesObject,
        patientPrivateInfos,
        roomInfos,
        participantCategoriesForHealthcareService,
        dayNote
    });

    // Create new DataSet object
    const dataSet = new window.Stimulsoft.System.Data.DataSet("Schedule");
    // Load JSON data file from specified URL to the DataSet object
    dataSet.readJson(printData);
    // Remove all connections from the report template
    report.dictionary.databases.clear();
    // Register DataSet object
    report.regData("Schedule", "Schedule", dataSet);
    // Synchronize data
    report.dictionary.synchronize();
    // Render report
    await report.renderAsync2();
    // Export as PDF
    const pdfData = await report.exportDocumentAsync2(Stimulsoft.Report.StiExportFormat.Pdf);
    // Get report file name
    const fileName = report.reportAlias;
    // Save data to file
    window.Stimulsoft.System.StiObject.saveAs(pdfData, `${fileName}-${systemDate}.pdf`, "application/pdf");
};
/**
 * format string from date ISO sring and keep the timezone in the ISO date string
 *
 * @callback SortSurgeriesByLocationAndTime
 * @param {PlanBox} a
 * @param {PlanBox} b
 * @return {number}
 */

const MAX_INT = Number.MAX_SAFE_INTEGER;

/**
 * sort surgeries by the location order and then the start time
 *
 * @param {Object<string, LocationLabelAndOrder>} locationOrder
 * @return {SortSurgeriesByLocationAndTime} sort function
 */
const sortSurgeriesByLocationAndTime = (locationOrder) => (a, b) => {
    // Set the location order
    // If no order is set in the locationOrder, put it last
    const aLocationOrder = locationOrder[a._location?.reference]?.order || MAX_INT;
    const bLocationOrder = locationOrder[b._location?.reference]?.order || MAX_INT;

    // Set the startTime order
    const aTimestampMilli = a._internalTimestamps?.duraRoomLockPre?.dtStart
        ? getDateTimeFromISO(a._internalTimestamps.duraRoomLockPre.dtStart).toMillis()
        : MAX_INT;
    const bTimestampMilli = b._internalTimestamps?.duraRoomLockPre?.dtStart
        ? getDateTimeFromISO(b._internalTimestamps.duraRoomLockPre.dtStart).toMillis()
        : MAX_INT;
    return aLocationOrder - bLocationOrder || aTimestampMilli - bTimestampMilli;
};

/**
 * Check if the selected dates are equal to or less than max future dates
 *
 * @param {Array<DateTimeType>} selectedDates
 * @param {DateTimeType} endOfToday
 * @param {number} maxFutureDates
 * @return {boolean}
 */
const checkNumberOfFutureDates = (selectedDates, endOfToday, maxFutureDates) => {
    const selectedFutureDates = selectedDates.filter((date) => date > endOfToday);
    return selectedFutureDates.length <= maxFutureDates;
};

/**
 * Check if the date is printable
 *   - The day is printable if it is today or in the past
 *   - The day is printable if it is in the future and is in the printableDates
 *
 * @param {DateTimeType} day the date to be verified if it's printable
 * @param {DateTimeType} endOfToday
 * @param {Array<DateTimeType>} printableDates
 * @param {function} areSame
 * @return {boolean}
 */
const checkPrintAllowed = (day, endOfToday, printableDates, areSame) =>
    (day <= endOfToday && diffDateTime(endOfToday, day, "days") <= config.MAX_PRINTABLE_DATE_PAST + 1) ||
    printableDates.some((printableDate) => areSame(printableDate, day, "day"));

/**
 * Check if both of the minimum printable date in the past and latest printable date in the future are within a same year
 *
 * @param {DateTimeType} endOfToday
 * @param {function} areSame
 * @param {DateTimeType} [latestPrintableDate]
 * @return {boolean}
 */
const isPrintableDatesTheSameYear = (endOfToday, areSame, latestPrintableDate) =>
    areSame(minusDT(endOfToday, "day", config.MAX_PRINTABLE_DATE_PAST), endOfToday, "year") &&
    (!latestPrintableDate || areSame(latestPrintableDate, endOfToday, "year"));

export {
    setupReporter,
    generateReport,
    checkReporter,
    formatObservations,
    generatePrintData,
    sortSurgeriesByLocationAndTime,
    checkNumberOfFutureDates,
    checkPrintAllowed,
    isPrintableDatesTheSameYear
};
